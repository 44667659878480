// State
export const state = () => ({
	loading: false,
	roles: [],
	permissions: [],
	permissionForCreate: [],
	permissionForUpdate: [],
	permissionModules: [],

	// cancel token
	permissionCancelToken: {}
})

// Actions
export const actions = {
	// ? Roles
	async roles({ commit, state }, params) {
		commit('setPermissionCancelToken', { roles: this.$axios.CancelToken.source() })
		try {
			commit('loading', true)
			await this.$axios
				.get(`/roles`, {
					params,
					cancelToken: state.permissionCancelToken.roles.token
				})
				.then((response) => {
					const dataWithSort = response.data.sort((b, a) => (a?.is_active > b?.is_active) ? 1 : ((b?.is_active > a?.is_active) ? -1 : 0))
					commit('roles', dataWithSort)
					commit('loading', false)
				})
		} catch (error) {
			commit('loading', false)
		}
	},

	// ? Permission modules (CRUD)
	async permissionModules({ commit, state }, roleId) {
		commit('setPermissionCancelToken', { permissionModules: this.$axios.CancelToken.source() })
		try {
			commit('loading', true)
			await this.$axios
				.get(`/permission-modules`, {
					params: { role_id: roleId },
					cancelToken: state.permissionCancelToken.permissionModules.token
				})
				.then((response) => {
					commit('permissionModules', response.data)
					commit('loading', false)
				})
		} catch (error) {
			commit('loading', false)
		}
	},

	// ? Permissions
	async permissions({ commit, state }, params) {
		commit('setPermissionCancelToken', { permissions: this.$axios.CancelToken.source() })
		try {
			commit('loading', true)
			await this.$axios
				.get(`/permissions`, {
					params,
					cancelToken: state.permissionCancelToken.permissions.token
				})
				.then((response) => {
					commit('permissions', response.data)
					commit('loading', false)
				})
		} catch (error) {
			commit('loading', false)
		}
	},
	async permissionForCreate({ commit, state }) {
		commit('setPermissionCancelToken', { permissionForCreate: this.$axios.CancelToken.source() })
		try {
			commit('loading', true)
			await this.$axios
				.get(`/permissions`, {
					cancelToken: state.permissionCancelToken.permissionForCreate.token
				})
				.then((response) => {
					commit('permissionForCreate', response.data)
					commit('loading', false)
				})
		} catch (error) {
			commit('loading', false)
		}
	},

	// ? Update permission by role / permission
	async update({ commit }, payload) {
		try {
			commit('loading', true)
			const newPermission = [];
			for (const level1 of payload.permissions) {
				for (const level2 of level1.permissions) {
					newPermission.push({
						permission_id: level2.permission_id,
						can_view: level2.can_view,
						can_create: level2.can_create,
						can_update: level2.can_update,
						can_delete: level2.can_delete,
					})
				}
			}
			const updatePermissions = await {
				role_update: {
					code: payload.form.code,
					description: payload.form.description,
					name: payload.form.name,
					is_active: payload.form.is_active
				},
				permission_modules: newPermission
			};
			const response = await this.$axios
				.put(`/roles/${payload.form.id}`, updatePermissions)
				.then(() => {
					this.$toast.success('Permissions are updated.')
					commit('loading', false)
					return true;
				})
				.catch(() => false)
			return response;
		} catch (error) {
			commit('loading', false)
		}
	},

	// ? Create permission by role / permission
	async create({ commit }, payload) {
		try {
			commit('loading', true)
			const newPermission = [];
			for (const level1 of payload.permissions) {
				for (const level2 of level1.permissions) {
					newPermission.push({
						permission_id: level2.id,
						can_view: level2.can_view,
						can_create: level2.can_create,
						can_update: level2.can_update,
						can_delete: level2.can_delete,
					})
				}
			}
			const createPermissions = await {
				...payload.form,
				permission_modules: newPermission,
			};
			return await this.$axios
				.post(`/roles`, createPermissions)
				.then(() => {
					commit('loading', false)
					return true;
				})
		} catch (error) {
			commit('loading', false)
			return false;
		}
	},

	// ? update permission
	async updatePermission({ commit, dispatch }, data) {
		const form = { ...data }
		const id = form.id;
		delete form.id;
		delete form.permissions
		try {
			commit('loading', true)
			const response = await this.$axios
				.put(`/permissions/${id}`, form)
				.then(() => {
					commit('loading', false)
					dispatch('permissions')
					return true;
				}).catch(() => {
					commit('loading', false)
					return false;
				})
			return response
		} catch (error) {
			commit('loading', false)
			return false;
		}
	},
	async updatePermissionStatus({ commit, dispatch }, params) {
		try {
			const form = { ...params }
			const id = form.id;
			delete form.id;
			const response = await this.$axios
				.put(`/roles/status/${id}`, form)
				.then(() => {
					return true;
				}).catch(() => {
					return false;
				})
			return response
		} catch (error) {
			return false;
		}
	}
}

// Mutations
export const mutations = {
	loading(state, type) {
		state.loading = type
	},
	roles(state, data) {
		state.roles = data
	},
	permissionModules(state, data) {
		state.permissionModules = data
	},
	permissions(state, data) {
		const sort = data.map(function (e) {
			e.permissions = e.permissions.map(function (subE) {
				return { ...subE, ...state.permissionModules.find(o => o.permission_id === subE.id) }
			})
			return e
		})
		const sortCanView = sort.map((item) => {
			const canView = item.permissions.filter((i) => i.can_view)
			const canCreate = item.permissions.filter((i) => i.can_create)
			const canUpdate = item.permissions.filter((i) => i.can_update)
			const canDelete = item.permissions.filter((i) => i.can_delete)
			return { ...item, can_view: (canView.length > 0), can_create: (canCreate.length > 0), can_update: (canUpdate.length > 0), can_delete: (canDelete.length > 0), }
		})
		state.permissions = sortCanView;
	},
	permissionForCreate(state, data) {
		state.permissionForCreate = data.map(function (e) {
			e.permissions = e.permissions.map(function (subE) {
				return {
					can_create: false,
					can_view: false,
					can_update: false,
					can_delete: false,
					...subE,
				}
			})
			return {
				can_create: false,
				can_view: false,
				can_update: false,
				can_delete: false,
				...e,
			}
		})
	},
	setPermissionCancelToken: (state, data) => {
		const oldData = { ...state.permissionCancelToken }
		state.permissionCancelToken = {
			...oldData, ...data
		}
	}
}

// Getters
export const getters = {
	findPermissionById: state => id => {
		return state.permissionModules.find(o => o.permission_id === id)
	}
}
