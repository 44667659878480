// State
export const state = () => ({
	loading: false,
	cryptos: [],

	// cancel token
	playerCryptoCancelToken: {}
});
// Actions
export const actions = {
	async cryptos({ commit, state }, id) {
		try {
			commit('loading', true)
			return await this.$axios
				.get(`/player-management/players/crypto-wallet/${id}`, {})
				.then((response) => {
					commit('cryptos', response.data)
					commit('loading', false)
				})
		} catch (error) {
			commit('loading', false)
		}
	},
	async createCrypto({ commit }, params) {
		try {
			commit('loading', true)
			const id = params.player_id;
			delete params.player_id
			return await this.$axios
				.post(`/player-management/players/crypto-wallet/${id}`, params)
				.then((response) => {
					commit('loading', false)
					return true;
				})
		} catch (error) {
			commit('loading', false)
			return false;
		}
	},
	async updateCrypto({ commit }, params) {
		try {
			const id = params.id;
			delete params.id;
			commit('loading', true)
			return await this.$axios
				.put(`/player-management/players/crypto-wallet/${id}`, params)
				.then((response) => {
					commit('loading', false)
					return true;
				})
		} catch (error) {
			commit('loading', false)
			return false;
		}
	},
	async deleteCrypto({ commit }, id) {
		try {
			commit('loading', true)
			return await this.$axios
				.delete(`/player-management/players/crypto-wallet/${id}`)
				.then((response) => {
					commit('loading', false)
					return true;
				})
		} catch (error) {
			commit('loading', false)
			return false;
		}
	},


}

// Mutations
export const mutations = {
	loading(state, loading) {
		state.loading = loading;
	},
	cryptos(state, data) {
		// sort is_primary bank account equal to true first then false
		const newSort = data.sort((a, b) => Number(b.is_primary) - Number(a.is_primary))
		state.cryptos = newSort;
	},
	playerCryptoCancelToken: (state, data) => {
		const oldData = { ...state.playerCryptoCancelToken }
		state.playerCryptoCancelToken = {
			...oldData, ...data
		}
	}
}