// State
export const state = () => ({
	loading: false,
	referralRates: [],
	preReferralRates: [],
	referralRatesConfig: {},

	// cancel token
	referralCancelToken: {}
});
// Actions
export const actions = {
	async referralRates({ commit, state }, params) {
		commit('setReferralCancelToken', { referralRates: this.$axios.CancelToken.source() })
		try {
			commit('loading', true)
			return await this.$axios
				.get(`/players-reward/referral-rate-settings`, {
					params,
					cancelToken: state.referralCancelToken.referralRates.token
				})
				.then((response) => {
					commit('referralRates', response.data)
					commit('loading', false)
				})
		} catch (error) {
			commit('loading', false)
		}
	},
	async updateReferralRate({ commit }, params) {
		try {
			const id = params.id;
			delete params.id;
			commit('loading', true)
			return await this.$axios
				.put(`/players-reward/referral-rate-settings/item/${id}`, params)
				.then((response) => {
					commit('loading', false)
					return true;
				})
		} catch (error) {
			commit('loading', false)
			return false;
		}
	},
	async deleteReferralRate({ commit, rootGetters }, id) {
		try {
			commit('loading', true)
			return await this.$axios
				.delete(`/players-reward/referral-rate-settings/item/${id}`)
				.then((response) => {
					this.$toast.success(rootGetters['master/storeTl']('rollingReferralRateSuccessful'))
					commit('loading', false)
					return true;
				})
		} catch (error) {
			commit('loading', false)
			return false;
		}
	},
	async createReferralRate({ commit }, params) {
		try {
			commit('loading', true)
			return await this.$axios
				.post(`/players-reward/referral-rate-settings/item`, params)
				.then((response) => {
					commit('loading', false)
					return true;
				})
		} catch (error) {
			commit('loading', false)
			return false;
		}
	},
	async createAndUpdateReferralRate({ commit }, params) {
		try {
			commit('loading', true)
			return await this.$axios
				.post(`/players-reward/referral-rate-settings`, params)
				.then((response) => {
					commit('loading', false)
					return response.data;
				})
		} catch (error) {
			commit('loading', false)
			return false;
		}
	},
	async applyReferral({ commit }, id) {
		try {
			commit('loading', true)
			return await this.$axios
				.put(`/players-reward/referral-rate-settings/apply/${id}`)
				.then((response) => {
					commit('loading', false)
					return response.data;
				})
		} catch (error) {
			commit('loading', false)
			return false;
		}
	},
}

// Mutations
export const mutations = {
	loading(state, loading) {
		state.loading = loading;
	},
	referralRates(state, data) {
		state.referralRates = data.referral_rate_settings;
		state.referralRatesConfig = data.referral_rate_setting_config
		state.preReferralRates = data.pre_referral_rate_settings
	},
	setReferralCancelToken: (state, data) => {
		const oldData = { ...state.referralCancelToken }
		state.referralCancelToken = {
			...oldData, ...data
		}
	}
}