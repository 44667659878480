// State
export const state = () => ({
	msg: null
})

// Actions
export const actions = {
	// default page
	confirmDeposit({ commit }, data) {
		commit('setData', data)
	},
	createWithdrawal({ commit }, data) {
		commit('setData', data)
		// if sound is enabled, show the alert notification
		if (this.$cookie.get('sound')) {
			commit('settings/setNewWithdrawalRequest', true, { root: true })
		}
	},
	clientWalletInfo({ dispatch }, msg) {
		dispatch('settings/setClientWalletInfo', msg, { root: true })
	},
	// deposit page
	lockDeposit({ commit }, data) {
		commit('setData', data)
	},
	confirmDepositByAdmin({ commit }, data) {
		commit('setData', data)
	},
	revokeDepositByAdmin({ commit }, data) {
		commit('setData', data)
	},
	confirmDepositByAutoTopup({ commit }, data) {
		commit('setData', data)
	},
	revokeDepositByAutoTopup({ commit }, data) {
		commit('setData', data)
	},
	unlockDeposit({ commit }, data) {
		commit('setData', data)
	},
	// withdrawal page
	unlockWithdrawal({ commit }, data) {
		commit('setData', data)
	},
	lockWithdrawal({ commit }, data) {
		commit('setData', data)
	},
	revokeWithdrawal({ commit }, data) {
		commit('setData', data)
	},
	confirmWithdrawal({ commit }, data) {
		commit('setData', data)
	},
	declinedWithdrawalByPlayer({ commit }, data) {
		commit('setData', data)
	}

}

// Mutations
export const mutations = {
	setData(state, type) {
		state.msg = type
	}
}