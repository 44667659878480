// State
export const state = () => ({
	loading: false,
	promotions: [],
	promotionId: [],
	excelData: [],
	promotion: {},

	// cancel token
	promotionCancelToken: {}
})


// Actions
export const actions = {
	// get all promotions
	async promotions({ commit, state }, params) {
		commit('setPromotionCancelToken', { promotions: this.$axios.CancelToken.source() })
		try {
			commit('loading', true)
			return await this.$axios
				.get(`/players-reward/promotions`, {
					params,
					cancelToken: state.promotionCancelToken.promotions.token
				})
				.then((response) => {
					commit('promotions', response.data)
					commit('loading', false)
				})
		} catch (error) {
			commit('loading', false)
		}
	},
	async promotion({ commit, state }, id) {
		commit('setPromotionCancelToken', { promotion: this.$axios.CancelToken.source() })
		try {
			commit('loading', true)
			return await this.$axios
				.get(`/players-reward/promotions/${id}`, {
					cancelToken: state.promotionCancelToken.promotion.token
				})
				.then((response) => {
					commit('setPromotion', response.data)
					commit('loading', false)
				})
		} catch (error) {
			commit('loading', false)
		}
	},
	// Create promotion
	async create({ commit }, data) {
		commit('loading', true)
		const response = await this.$axios.post(`/players-reward/promotions`, data).then(() => {
			commit('loading', false)
			return true
			// this.$router.push('/player-reward/promotion')
		}).catch(() => {
			commit('loading', false)
			return false
		})
		return response;
	},
	// Delete promotion
	async delete({ dispatch }, id) {
		await this.$axios.delete(`/players-reward/promotions/${id}`)
			.then((response) => {
				return true
			})
			.catch((e) => { return false })
	},
	// update
	async updatePromotion({ dispatch }, form) {
		const response = await this.$axios.put(`/players-reward/promotions/${form.id}`, form.data)
			.then(() => {
				return true
			})
			.catch((e) => { return false })
		return response;
	},

	// update promotion sequence
	async updatePromotionSequence({ commit }, data) {
		commit('loading', true)
		const response = await this.$axios.put(`/players-reward/promotions/sequence`, data)
			.then(() => {
				// this.$router.push(('/player-reward/promotion'))
				commit('loading', false)
				return true

			})
			.catch((e) => {
				commit('loading', false)
				return false
			})
		return response
	},
}


// Mutations
export const mutations = {
	loading(state, type) {
		state.loading = type
	},
	promotions(state, data) {
		state.promotions = data
	},
	setPromotion(state, data) {
		state.promotion = data;
	},
	setPromotionCancelToken: (state, data) => {
		const oldData = { ...state.promotionCancelToken }
		state.promotionCancelToken = {
			...oldData, ...data
		}
	}
}