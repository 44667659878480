// State
export const state = () => ({
	operators: [],
	operator: {},
	subOperators: [],
	exchangeRates: [],
	brands: [],
	players: {
		data: []
	},
	bets: {},
	quotaLogs: {
		total: 0,
		data: []
	},
	loading: false,
	reportGgrs: [],
	companyReports: [],
	reports: [],
	depositPlayer: {
		total: 0,
		data: []
	},
	withdrawalPlayer: {
		total: 0,
		data: []
	},
	tablePlayerParamsDeposit: {},
	tablePlayerParamsWithdrawal: {},
	banners: {
		players: {
			direct: [],
			associate: [],
		},
		deposit: {
			direct: [],
			associate: []
		},
		withdraw: {
			direct: [],
			associate: []
		}
	},
	agentList: [],
	allBanners: [],
	cancelTokens: {},
	tempOperatorId: null,
	currencies: [],
	operatorSummary: {
		marketing_price: {},
		total_api_consumption_real_cost: {},
		total_ggr_amount_main_operation: {},
		total_ggr_amount_sub_admin: {},
		total_turnover_main_operation: {},
		total_turnover_sub_admin: {},
		affiliate_cost: {}
	},
	operatorSummaryLoading: false,
	companyReportTemporaryCurrency: null
})

// Actions
export const actions = {
	async brands({ commit, state }, params) {
		if (state.cancelTokens.brands) {
			state.cancelTokens.brands.cancel('')
		}
		commit('setCancelTokens', { brands: this.$axios.CancelToken.source() })

		const response = await this.$axios.get('/csl-master/master/brands',
			{
				params,
				cancelToken: state.cancelTokens.brands.token
			}
		)
			.then((data) => {
				commit('setBands', data.data)
				return true;
			})
			.catch(() => false)
		return response
	},
	async operators({ commit, state }, params) {
		if (state.cancelTokens.operators) {
			state.cancelTokens.operators.cancel('')
		}
		commit('setCancelTokens', { operators: this.$axios.CancelToken.source() })
		await this.$axios.get('/csl-master/operator',
			{
				params,
				cancelToken: state.cancelTokens.operators.token
			}
		)
			.then((data) => {
				commit('setOperators', data.data)
				return true;
			})
			.catch(() => false)
	},
	async operatorsMaster({ commit, state }, params) {
		if (state.cancelTokens.operators) {
			state.cancelTokens.operators.cancel('')
		}
		commit('setCancelTokens', { operators: this.$axios.CancelToken.source() })
		await this.$axios.get('/csl-master/master/operator',
			{
				params,
				cancelToken: state.cancelTokens.operators.token
			}
		)
			.then((data) => {
				commit('setOperators', data.data)
				return true;
			})
			.catch(() => false)
	},
	async operator({ commit, state }, id) {
		if (state.cancelTokens.operator) {
			state.cancelTokens.operator.cancel('')
		}
		commit('setCancelTokens', { operator: this.$axios.CancelToken.source() })
		commit('setLoading', true)
		await this.$axios.get(`/csl-master/operator/${id}`, {
			cancelToken: state.cancelTokens.operator.token
		})
			.then((data) => {
				commit('setOperator', data.data)
				return true;
			})
			.catch(() => false)
		commit('setLoading', false)
	},
	async subOperator({ commit, state }, id) {
		if (state.cancelTokens.subOperator) {
			state.cancelTokens.subOperator.cancel('')
		}
		commit('setCancelTokens', { subOperator: this.$axios.CancelToken.source() })
		await this.$axios.get(`/csl-master/master/sub-operator/${id}`, { cancelToken: state.cancelTokens.subOperator.token })
			.then((data) => {
				commit('setSubOperators', data.data)
				return true;
			})
			.catch(() => false)
	},
	async createSubOperator({ commit }, form) {
		const response = await this.$axios.post(`/csl-master/operator`, form)
			.then((data) => {
				return data.data
			})
			.catch(() => false)
		return response;
	},
	async getExchangeRates({ commit, state }, form) {
		if (state.cancelTokens.getExchangeRates) {
			state.cancelTokens.getExchangeRates.cancel('')
		}
		commit('setCancelTokens', { getExchangeRates: this.$axios.CancelToken.source() })
		const response = await this.$axios.get(`/csl-master/master/exchange-rate`, {
			cancelToken: state.cancelTokens.getExchangeRates.token
		})
			.then((data) => {
				commit('setExchangeRates', data.data)
			})
			.catch(() => false)
		return response;
	},
	async updateOperator({ commit }, { id, form }) {
		const response = await this.$axios.put(`/csl-master/operator/${id}`, form)
			.then((data) => {
				return true;
			})
			.catch(() => false)
		return response;
	},
	async updateOperatorQuota({ commit }, { id, form }) {
		const response = await this.$axios.put(`/csl-master/operator/quota/${id}`, form)
			.then((data) => {
				return true;
			})
			.catch(() => false)
		return response;
	},
	async getPlayers({ commit, state }, form) {
		const params = { ...form }
		const id = params.id
		delete params.id

		if (state.cancelTokens.getPlayers) {
			state.cancelTokens.getPlayers.cancel('')
		}
		commit('setCancelTokens', { getPlayers: this.$axios.CancelToken.source() })
		const response = await this.$axios.get(`/csl-master/operator/players/${id}`, {
			params,
			cancelToken: state.cancelTokens.getPlayers.token
		})
			.then((data) => {
				commit('setPlayer', data.data)
			})
			.catch(() => false)
		return response;
	},
	async getBetHistory({ commit, state }, form) {
		const params = { ...form }
		const id = params.id
		delete params.id
		const response = await this.$axios.get(`/csl-master/operator/bet-history/${id}`, {
			params,
		})
			.then((data) => {
				commit('setBets', data.data)
			})
			.catch(() => false)
		return response;
	},
	async quotaLogs({ commit, state }, params) {
		if (state.cancelTokens.quotaLogs) {
			state.cancelTokens.quotaLogs.cancel('')
		}
		commit('setCancelTokens', { quotaLogs: this.$axios.CancelToken.source() })
		const response = await this.$axios.get(`/csl-master/operator/quota-logs`,
			{
				params,
				cancelToken: state.cancelTokens.quotaLogs.token
			})
			.then((data) => {
				commit('setQuotaLogs', data.data)
			})
			.catch(() => false)
		return response;
	},
	async reportGgrs({ commit, state }, form) {
		const id = form.id
		const params = form.params

		if (state.cancelTokens.reportGgrs) {
			state.cancelTokens.reportGgrs.cancel('')
		}
		commit('setCancelTokens', { reportGgrs: this.$axios.CancelToken.source() })
		const response = await this.$axios.get(`/csl-master/reports/ggr/${id}`,
			{
				params,
				cancelToken: state.cancelTokens.reportGgrs.token
			})
			.then((data) => {
				commit('setReportGgrs', data.data)
			})
			.catch(() => false)
		return response;
	},
	async companyReports({ commit, state }, params) {
		if (params.operator_id) {
			commit('setTempOperatorId', params.operator_id)
		} else {
			// make default value
			commit('setTempOperatorId', null)
		}
		commit('setLoading', true)
		if (state.cancelTokens.companyReports) {
			state.cancelTokens.companyReports.cancel('')
		}
		commit('setCancelTokens', { companyReports: this.$axios.CancelToken.source() })
		const newParams = { ...params }
		if (params.currency === 'commonUSD') {
			delete newParams.currency
		}
		const response = await this.$axios.get(`/csl-master/reports/company`,
			{
				params: newParams,
				cancelToken: state.cancelTokens.companyReports.token
			})
			.then((data) => {
				commit('setCompanyReports', data.data)
				if (params.currency) {
					commit('setCompanyReportTemporaryCurrency', params.currency)
				}
			})
			.catch(() => false)
		commit('setLoading', false)
		return response;
	},
	async reports({ commit, state }, params) {
		if (state.cancelTokens.reports) {
			state.cancelTokens.reports.cancel('')
		}
		commit('setCancelTokens', { reports: this.$axios.CancelToken.source() })
		const response = await this.$axios.get(`/csl-master/reports`,
			{
				params,
				cancelToken: state.cancelTokens.reports.token
			})
			.then((data) => {
				commit('setReports', data.data)
			})
			.catch(() => false)
		return response;
	},
	async playerWithdrawal({ commit, state }, params) {
		if (state.cancelTokens.playerWithdrawal) {
			state.cancelTokens.playerWithdrawal.cancel('')
		}
		if (params?.limit && params?.page) {
			commit('setTablePlayerParamsWithdrawal', { limit: params.limit, page: params.page })
		}
		commit('setCancelTokens', { playerWithdrawal: this.$axios.CancelToken.source() })
		const response = await this.$axios.get(`/csl-master/reports/players/withdraw`,
			{
				params: { ...state.tablePlayerParamsWithdrawal },
				cancelToken: state.cancelTokens.playerWithdrawal.token
			})
			.then((data) => {
				commit('setWithdrawalPlayer', data.data)
			})
			.catch(() => false)
		return response;
	},
	async playerDeposit({ commit, state }, params) {
		if (state.cancelTokens.playerDeposit) {
			state.cancelTokens.playerDeposit.cancel('')
		}
		if (params?.limit && params?.page) {
			commit('setTablePlayerParamsDeposit', { limit: params.limit, page: params.page })
		}
		commit('setCancelTokens', { playerDeposit: this.$axios.CancelToken.source() })
		const response = await this.$axios.get(`/csl-master/reports/players/deposit`,
			{
				params: { ...state.tablePlayerParamsDeposit },
				cancelToken: state.cancelTokens.playerDeposit.token
			})
			.then((data) => {
				commit('setDepositPlayer', data.data)
			})
			.catch(() => false)
		return response;
	},
	async reportBanners({ commit, state }, params) {
		commit('setCancelTokens', { reportBanners: this.$axios.CancelToken.source() })
		const response = await this.$axios.get(`/csl-master/reports/banner`,
			{
				params,
				cancelToken: state.cancelTokens.reportBanners.token
			})
			.then((data) => {
				commit('setReportBanners', data.data)
			})
			.catch(() => false)
		return response;
	},
	async masterAgents({ commit, state }, params) {
		commit('setCancelTokens', { masterAgents: this.$axios.CancelToken.source() })
		const response = await this.$axios.get(`/csl-master/operator/agents`,
			{
				params,
				cancelToken: state.cancelTokens.masterAgents.token
			})
			.then((data) => {
				commit('setAgentList', data.data)
			})
			.catch(() => false)
		return response;
	},
	async bannerAgents({ commit, state }, params) {
		commit('setCancelTokens', { masterAgents: this.$axios.CancelToken.source() })
		const response = await this.$axios.get(`/csl-master/operator/banners`,
			{
				params,
				cancelToken: state.cancelTokens.masterAgents.token
			})
			.then((data) => {
				commit('setAllBanners', data.data)
			})
			.catch(() => false)
		return response;
	},
	async operatorCurrencies({ commit, state }, params) {
		commit('setCancelTokens', { operatorCurrencies: this.$axios.CancelToken.source() })
		const response = await this.$axios.get(`/csl-master/operator/currencies`,
			{
				params,
				cancelToken: state.cancelTokens.operatorCurrencies.token
			})
			.then((data) => {
				commit('setCurrencies', data.data)
			})
			.catch(() => false)
		return response;
	},
	async operatorSummaryByOperatorId({ commit, state }, params) {
		commit('setOperatorSummaryLoading', true)
		commit('setCancelTokens', { operatorSummaryByOperatorId: this.$axios.CancelToken.source() })
		const response = await this.$axios.get(`/csl-master/reports/operator/summary`,
			{
				params,
				cancelToken: state.cancelTokens.operatorSummaryByOperatorId.token
			})
			.then((data) => {
				commit('setOperatorSummary', data.data)
			})
			.catch(() => false)
		commit('setOperatorSummaryLoading', false)
		return response;
	},
	async suspendOperator({ commit }, id) {
		const response = await this.$axios.put(`/csl-master/operator/suspend/${id}`,)
			.then((data) => {
				return true;
			})
			.catch(() => false)
		return response;
	},

}

// Mutations
export const mutations = {
	setOperators(state, response) {
		state.operators = response
	},
	setOperator(state, data) {
		state.operator = data
	},
	setSubOperators(state, data) {
		state.subOperators = data
	},
	setExchangeRates(state, data) {
		state.exchangeRates = data
	},
	setBands(state, data) {
		state.brands = data
	},
	setPlayer(state, data) {
		state.players = data
	},
	setBets(state, data) {
		state.bets = data
	},
	setQuotaLogs(state, data) {
		state.quotaLogs = data
	},
	setLoading(state, type) {
		state.loading = type
	},
	setReportGgrs(state, data) {
		state.reportGgrs = data
	},
	setCompanyReports(state, data) {
		state.companyReports = data
	},
	setReports(state, data) {
		state.reports = data
	},
	setWithdrawalPlayer(state, data) {
		state.withdrawalPlayer = data
	},
	setDepositPlayer(state, data) {
		state.depositPlayer = data
	},
	setReportBanners(state, data) {
		state.banners = data
	},
	setTablePlayerParamsDeposit(state, data) {
		state.tablePlayerParamsDeposit = { ...state.tablePlayerParamsDeposit, ...data }
	},
	setTablePlayerParamsWithdrawal(state, data) {
		state.tablePlayerParamsWithdrawal = { ...state.tablePlayerParamsWithdrawal, ...data }
	},
	setAgentList(state, data) {
		state.agentList = data
	},
	setAllBanners(state, data) {
		state.allBanners = data
	},
	setTempOperatorId(state, data) {
		state.tempOperatorId = data
	},
	setCurrencies(state, data) {
		state.currencies = data
	},
	setOperatorSummary(state, data) {
		state.operatorSummary = data
	},
	setCompanyReportTemporaryCurrency(state, data) {
		state.companyReportTemporaryCurrency = data
	},
	setOperatorSummaryLoading(state, data) {
		state.operatorSummaryLoading = data
	},
	setCancelTokens(state, data) {
		const oldData = { ...state.cancelTokens }
		state.cancelTokens = {
			...oldData, ...data
		}
	}
}


export const getters = {
	// splitting out sub operators in a single list of array
	operatorsInSingleList: (state) => {
		const newList = []
		const cloneOperators = JSON.parse(JSON.stringify(state.operators))
		if (cloneOperators.length) {
			// This key is the main operator brand name. It's used to sort the sub operators by its main brand name
			const priorityKey = cloneOperators[0].brand_name

			const sorted = cloneOperators[0].sub_operators.sort((a, b) => a.brand_name.localeCompare(b.brand_name))

			cloneOperators[0].sub_operators = sorted.sort((a, b) => {
				if (a.brand_name === priorityKey) return -1;
				if (b.brand_name === priorityKey) return 1;
				return a.brand_name.localeCompare(b.brand_name)
			})

		}

		cloneOperators.forEach(mainOperator => {
			const copyMainOperator = JSON.parse(JSON.stringify(mainOperator));
			delete copyMainOperator.sub_operators
			newList.push(copyMainOperator)
			if (mainOperator?.sub_operators?.length > 0) {
				mainOperator.sub_operators.forEach(subOperator => {
					const copySubOperator = JSON.parse(JSON.stringify(subOperator))
					delete copySubOperator.child_operators
					newList.push(copySubOperator)

					if (subOperator?.child_operators?.length > 0) {
						subOperator.child_operators.forEach(childOperator => {
							newList.push(childOperator)
						})
					}

				})
			}

		})
		return newList
	},
	getBrands: (state) => {
		// ordering the brand, unshift the main operator to be the first brands list
		if (state.operators.length && state?.operators[0]?.brand_id) {
			const brandId = state?.operators[0]?.brand_id
			const findBrand = state.brands.findIndex(el => el._id === brandId)

			if (findBrand >= 0) {
				const cloneBrands = JSON.parse(JSON.stringify(state.brands))
				const cloneObject = JSON.parse(JSON.stringify(cloneBrands[findBrand]))
				cloneBrands.splice(findBrand, 1)
				cloneBrands.unshift(cloneObject)
				// New Change, Thu 1 Feb 2024. Bz team need to add one more Brand Performance For Display only the main operator.
				// And main operator still needs to be display in each tables of operator tables, that's why the below line code added
				cloneBrands.unshift({ ...cloneObject, _id: null })
				return cloneBrands
			}
		}
		return state.brands
	},
	companyReportsGroup: (state) => {
		return state.companyReports
	}
}