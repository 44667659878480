// import axios from 'axios'
export const state = () => ({
	loading: false,
	languageStrings: {},
	newCodes: [], // ? this is used for stopping making new request of addLanguageStrings multiple times, check global.js you will see
	translationString: {},
	loadingStringsForTranslation: false,


	// cancel token
	stringCancelToken: {}
})

export const actions = {
	async affiliateLanguageStrings({ commit }, params) {
		commit('setLoading', true)
		try {
			await this.$axios.get('affiliates/language-strings', { params: { limit: 2000, ...params } }).then((response) => {
				commit('setLanguageString', { data: [...response.data] })
			})
			commit('setLoading', false)
			return true;
		} catch (error) {
			commit('setLoading', false)
			return false
		}
	},
	async languageStrings({ commit, state }, params) {
		commit('setStringCancelToken', { languageStrings: this.$axios.CancelToken.source() })
		commit('setLoading', true)
		try {
			await this.$axios.get('/language-strings', {
				params,
				cancelToken: state.stringCancelToken.languageStrings.token
			}).then((response) => {
				commit('setLanguageString', { data: [...response.data] })
			})
			commit('setLoading', false)
			return true;
		} catch (error) {
			commit('setLoading', false)
			return false
		}
	},
	async addLanguageStrings({ commit }, form) {
		commit('setLoading', true)
		try {
			const response = await this.$axios.post('/language-strings', form)
				.then((response) => {
					if (response.status === 201) {
						return true;
					}
				})
				.catch(() => {
					return false
				})
			commit('setLoading', false)
			return response;
		} catch (error) {
			commit('setLoading', false)
			return false
		}
	},
	newCode({ commit }, code) {
		commit('newCode', code)
	},
	async translationStrings({ commit }, language = 'th-TH') {
		try {
			// read and understand on README.md file
			let path = `/language-strings/language/${language}`

			commit('setLoading', true)
			if (!this.$config.DEVELOPMENT && process.server) {
				path = this.$config.SERVER_URL + path
			}
			await this.$axios.get(path, {
				params: {
					used_in: '1,2'
				}
			})
				.then((response) => {
					const convertArrayToObject = Object.fromEntries(response.data.map(item => [item.code, item.string]));
					commit('setTranslationStrings', convertArrayToObject)
					commit('setLoading', false)
				})
				.catch((er) => {
					return false
				})

			commit('setLoading', false)
		} catch (error) {
			return false
		}
	}

}

export const mutations = {
	setLoading: (state, v) => {
		state.loading = v
	},
	setLanguageString: (state, v) => {
		state.languageStrings = v
	},
	setTranslationStrings: (state, v) => {
		state.translationString = v
	},
	loadingStringsForTranslation: (state, v) => {
		state.loadingStringsForTranslation = v
	},
	setStringCancelToken: (state, data) => {
		const oldData = { ...state.stringCancelToken }
		state.stringCancelToken = {
			...oldData, ...data
		}
	}
}

export const getters = {
	localeLanguage: state => language => {
		if (!language) {
			return {}
		}
		if (!state.languageStrings?.data?.length) return {}
		const translation = state.languageStrings.data.map((data) => {
			return {
				code: data.code,
				text: data.text[language]
			}
		})
		return translation;
	},
	storeTl: state => code => {
		if (state.translationString[code]) return state.translationString[code]
		return code
	}
}
