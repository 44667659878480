// State
export const state = () => ({
	loading: false,
	promotionCodes: [],
	promotionDownload: {},
	promotionCode: {},
	singlePromotionCode: {},

	// cancel token
	promotionCodeCancelToken: {}
});
// Actions
export const actions = {
	async promotionCodes({ commit, state }, params) {
		commit('setPromotionCodeCancelToken', { promotionCodes: this.$axios.CancelToken.source() })
		try {
			commit('loading', true)
			return await this.$axios
				.get(`/players-reward/promotion-codes`, {
					params,
					cancelToken: state.promotionCodeCancelToken.promotionCodes.token
				})
				.then((response) => {
					commit('promotionCodes', response.data)
					commit('loading', false)
				})
		} catch (error) {
			commit('loading', false)
		}
	},
	async promotionCodeById({ commit, state }, id) {
		commit('setPromotionCodeCancelToken', { promotionCodeById: this.$axios.CancelToken.source() })
		try {
			commit('loading', true)
			return await this.$axios
				.get(`/players-reward/promotion-codes/${id}`, {
					cancelToken: state.promotionCodeCancelToken.promotionCodeById.token
				})
				.then((response) => {
					commit('setSinglePromotionCode', response.data)
					commit('loading', false)
				})
		} catch (error) {
			commit('loading', false)
		}
	},
	async promotionDownload({ commit }, id) {
		try {
			commit('loading', true)
			return await this.$axios
				.get(`players-reward/promotion-codes/download/${id}`)
				.then((response) => {
					commit('promotionDownload', response.data)
					commit('loading', false)
					return response.data;
				})
		} catch (error) {
			commit('loading', false)
			return false;
		}
	},
	async createPromotionCode({ commit }, params) {
		try {
			commit('loading', true)
			return await this.$axios
				.post(`/players-reward/promotion-codes`, params)
				.then((response) => {
					commit('loading', false)
					return response.data;
				})
		} catch (error) {
			commit('loading', false)
		}
	},
	promotionCode({ commit }, params) {
		commit('promotionCode', params)
	},
	async updatePromotionCode({ commit }, params) {
		try {
			commit('loading', true)
			return await this.$axios
				.put(`/players-reward/promotion-codes/${params.id}`, { is_active: params.is_active })
				.then((response) => {
					commit('loading', false)
					return response.data;
				})
		} catch (error) {
			commit('loading', false)
		}
	},

}

// Mutations
export const mutations = {
	loading(state, loading) {
		state.loading = loading;
	},
	promotionCodes(state, data) {
		state.promotionCodes = data;
	},
	promotionDownload(state, data) {
		state.promotionDownload = data;
	},
	promotionCode(state, data) {
		state.promotionCode = data;
	},
	inviteAndEarnReports(state, data) {
		state.inviteAndEarnReports = data;
	},
	setSinglePromotionCode(state, data) {
		state.singlePromotionCode = data;
	},
	setPromotionCodeCancelToken: (state, data) => {
		const oldData = { ...state.promotionCodeCancelToken }
		state.promotionCodeCancelToken = {
			...oldData, ...data
		}
	}
}