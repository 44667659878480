export const state = () => ({
	loading: false,
	// All Summary States
	totals: {},
	players: {},
	summaryOfDeposit: {},
	topFiveDirectPlayers: [],
	topFiveAssociatePlayers: [],
	summaryOfWithdrawal: {},
	bonus: {},
	manualAdjustmentPositive: {},
	manualAdjustmentNegative: {},
	highestDepositPlayers: [],
	highestWithdrawalPlayers: [],
	// Deposit Summary
	depositSummary: {},
	promotionsWithDepositAndBonus: [],
	// Withdrawal Summary
	withdrawalSummary: {},
	statsCancelToken: {}
})

// since all the routes using the same path
const mainRoute = '/statistics/lwl/summary'

// Actions
export const actions = {
	async totals({ commit, state }, params) {
		commit('setStatsCancelToken', { totals: this.$axios.CancelToken.source() })
		await this.$axios
			.get(`${mainRoute}/totals`, {
				params,
				cancelToken: state.statsCancelToken.totals.token
			})
			.then((response) => {
				commit('totals', response.data)
			})
	},
	async players({ commit, state }, params) {
		commit('setStatsCancelToken', { players: this.$axios.CancelToken.source() })
		await this.$axios
			.get(`${mainRoute}/players`, {
				params,
				cancelToken: state.statsCancelToken.players.token
			})
			.then((response) => {
				commit('players', response.data)
			})
	},
	async summaryOfDeposit({ commit, state }, params) {
		commit('setStatsCancelToken', { summaryOfDeposit: this.$axios.CancelToken.source() })
		await this.$axios
			.get(`${mainRoute}/summary-of-deposit`, {
				params,
				cancelToken: state.statsCancelToken.summaryOfDeposit.token
			})
			.then((response) => {
				commit('summaryOfDeposit', response.data)
			})
	},
	async topFiveDirectPlayers({ commit, state }, params) {
		commit('setStatsCancelToken', { topFiveDirectPlayers: this.$axios.CancelToken.source() })
		await this.$axios
			.get(`${mainRoute}/top-five-direct-players-deposit`, {
				params,
				cancelToken: state.statsCancelToken.topFiveDirectPlayers.token
			})
			.then((response) => {
				commit('topFiveDirectPlayers', response.data)
			})
	},
	async topFiveAssociatePlayers({ commit, state }, params) {
		commit('setStatsCancelToken', { topFiveAssociatePlayers: this.$axios.CancelToken.source() })
		await this.$axios
			.get(`${mainRoute}/top-five-associate-players-deposit`, {
				params,
				cancelToken: state.statsCancelToken.topFiveAssociatePlayers.token
			})
			.then((response) => {
				commit('topFiveAssociatePlayers', response.data)
			})
	},
	async summaryOfWithdrawal({ commit, state }, params) {
		commit('setStatsCancelToken', { summaryOfWithdrawal: this.$axios.CancelToken.source() })
		await this.$axios
			.get(`${mainRoute}/summary-of-withdrawal`, {
				params,
				cancelToken: state.statsCancelToken.summaryOfWithdrawal.token
			})
			.then((response) => {
				commit('summaryOfWithdrawal', response.data)
			})
	},
	async bonus({ commit, state }, params) {
		commit('setStatsCancelToken', { bonus: this.$axios.CancelToken.source() })
		await this.$axios
			.get(`${mainRoute}/bonus`, {
				params,
				cancelToken: state.statsCancelToken.bonus.token
			})
			.then((response) => {
				commit('bonus', response.data)
			})
	},
	async manualAdjustmentPositive({ commit, state }, params) {
		commit('setStatsCancelToken', { manualAdjustmentPositive: this.$axios.CancelToken.source() })
		await this.$axios
			.get(`${mainRoute}/manual-adjustment-positive`, {
				params,
				cancelToken: state.statsCancelToken.manualAdjustmentPositive.token
			})
			.then((response) => {
				commit('manualAdjustmentPositive', response.data)
			})
	},
	async manualAdjustmentNegative({ commit, state }, params) {
		commit('setStatsCancelToken', { manualAdjustmentNegative: this.$axios.CancelToken.source() })
		await this.$axios
			.get(`${mainRoute}/manual-adjustment-negative`, {
				params,
				cancelToken: state.statsCancelToken.manualAdjustmentNegative.token
			})
			.then((response) => {
				commit('manualAdjustmentNegative', response.data)
			})
	},
	async highestDepositPlayers({ commit, state }, params) {
		commit('setStatsCancelToken', { highestDepositPlayers: this.$axios.CancelToken.source() })
		await this.$axios
			.get(`${mainRoute}/highest-deposit-players`, {
				params,
				cancelToken: state.statsCancelToken.highestDepositPlayers.token
			})
			.then((response) => {
				commit('highestDepositPlayers', response.data)
			})
	},
	async highestWithdrawalPlayers({ commit, state }, params) {
		commit('setStatsCancelToken', { highestWithdrawalPlayers: this.$axios.CancelToken.source() })
		await this.$axios
			.get(`${mainRoute}/highest-withdrawal-players`, {
				params,
				cancelToken: state.statsCancelToken.highestWithdrawalPlayers.token
			})
			.then((response) => {
				commit('highestWithdrawalPlayers', response.data)
			})
	},
	async depositSummary({ commit, state }, params) {
		commit('setStatsCancelToken', { depositSummary: this.$axios.CancelToken.source() })
		await this.$axios
			.get(`${mainRoute}/deposit`, {
				params,
				cancelToken: state.statsCancelToken.depositSummary.token
			})
			.then((response) => {
				commit('depositSummary', response.data)
			})
	},
	async promotionsWithDepositAndBonus({ commit, state }, params) {
		commit('setStatsCancelToken', { promotionsWithDepositAndBonus: this.$axios.CancelToken.source() })
		await this.$axios
			.get(`/statistics/summary/deposit/promotions`, {
				params,
				cancelToken: state.statsCancelToken.promotionsWithDepositAndBonus.token
			})
			.then((response) => {
				commit('promotionsWithDepositAndBonus', response.data)
			})
	},
	async withdrawalSummary({ commit, state }, params) {
		commit('setStatsCancelToken', { withdrawalSummary: this.$axios.CancelToken.source() })
		await this.$axios
			.get(`${mainRoute}/withdrawal`, {
				params,
				cancelToken: state.statsCancelToken.withdrawalSummary.token
			})
			.then((response) => {
				commit('withdrawalSummary', response.data)
			})
	},
}

// Mutations
export const mutations = {
	loading(state, type) {
		state.loading = type
	},
	totals: (state, v) => {
		state.totals = v
	},
	players: (state, v) => {
		state.players = v
	},
	summaryOfDeposit: (state, v) => {
		state.summaryOfDeposit = v
	},
	topFiveDirectPlayers: (state, v) => {
		state.topFiveDirectPlayers = v
	},
	topFiveAssociatePlayers: (state, v) => {
		state.topFiveAssociatePlayers = v
	},
	summaryOfWithdrawal: (state, v) => {
		state.summaryOfWithdrawal = v
	},
	bonus: (state, v) => {
		state.bonus = v
	},
	manualAdjustmentPositive: (state, v) => {
		state.manualAdjustmentPositive = v
	},
	manualAdjustmentNegative: (state, v) => {
		state.manualAdjustmentNegative = v
	},
	highestDepositPlayers: (state, v) => {
		state.highestDepositPlayers = v
	},
	highestWithdrawalPlayers: (state, v) => {
		state.highestWithdrawalPlayers = v
	},
	depositSummary: (state, v) => {
		state.depositSummary = v
	},
	promotionsWithDepositAndBonus: (state, v) => {
		state.promotionsWithDepositAndBonus = v
	},
	withdrawalSummary: (state, v) => {
		state.withdrawalSummary = v
	},
	setStatsCancelToken: (state, data) => {
		const oldData = { ...state.statsCancelToken }
		state.statsCancelToken = {
			...oldData, ...data
		}
	}
}